
  @media only screen and (max-width: 980px) {
    .containerner{
        margin-top: -100px;
    }
    
  }
  @media only screen and (max-width: 640px) {
    .containerner{
        margin-top: -600px;
    }
  }
  
   .buttononon{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
   }
   .rkkrrkrkrk img{
    width: 130px;
}
@media only screen  and (max-width: 768px){
    .rkkrrkrkrk img{
        width: 100px;
        height: 100px;
    }
}

.contact-top{
  margin-top: 60px;
}