footer {
  background-color: #121315;
  color: #ffffff;
  font-size: 16px;
}
.row {
  padding: 2em 1em;
}
.row.primary {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  align-items: stretch;
}
.column {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 2em;

}
h8 {
  width: 100%;
  text-align: left;
  color: #ffc506;
  font-size: 1.6em;
  white-space: nowrap;
}
.yatayati{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px;
}
ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}
li:not(:first-child) {
  margin-top: 0.8em;
}
ul li a {
  color: #ffffff65;
  text-decoration: none;
}
ul li a:hover {
  color: #ffc506;
}
.about p {
  text-align: justify;
  line-height: 2;
  margin: 0;
}
input,
.button1 {
  font-size: 1em;
  padding: 1em;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 5px;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.button1 {
  background-color: #ffc506;
  color: #000000;
  width: 50%;
  font-weight: 600;
  border: none;
  
}
div.social {
  display: flex;
  font-size: 1.4em;
  flex-direction: row;
  margin-top: 0.5em;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.social i{
  margin-left: 20px;
}
.social i:hover {
  color: #ffc506;
}
/* .row.secondary {
  display: flex;
  justify-content: space-around;
  text-align: center;
}
.row.secondary i {
  font-size: 1.8em;
  color: #2a8ded;
}
.row.secondary div {
  padding: 1em 0;
  width: 100%;
}
.row.secondary div:hover {
  background-color: #25262e;
} */
.copyright {
  padding: 0.9em 1em;
  background-color: #25262e;
  height: 50px;
}
.copyright p {
  font-size: 0.9em;
  text-align: center;
}
@media screen and (max-width: 850px) {
  .row.primary {
    grid-template-columns: 1fr;
  }
  .row.secondary {
    flex-direction: column;
  }
}
.right-corner {
  position: fixed;
  top: 20px; /* Adjust the top position as needed */
  right: 20px; /* Adjust the right position as needed */
  z-index: 1000; /* Ensure the alert is on top of other content */
}