.cookie-card {
    position: fixed;
    bottom: 20px;
    left: 20px;
    max-width: 320px;
    padding: 1rem;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 20px 20px 30px rgba(0, 0, 0, .05);
    z-index: 999999;
  }
  
  @media screen and (max-width: 768px) {
    .cookie-card {
      max-width: 70%; /* Adjust as needed */
      left: 50%;
      transform: translateX(-50%);
    }
  }
  
  .title {
    font-weight: 600;
    color: rgb(31 41 55);
  }
  
  .description {
    margin-top: 1rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: rgb(75 85 99);
  }
  
  .description a {
    --tw-text-opacity: 1;
    color: rgb(59 130 246);
  }
  
  .description a:hover {
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
  }
  
  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    -moz-column-gap: 1rem;
    column-gap: 1rem;
    flex-shrink: 0;
  }
  
  .pref {
    font-size: 0.75rem;
    line-height: 1rem;
    color: rgb(31 41 55 );
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
    transition: all .3s cubic-bezier(0.4, 0, 0.2, 1);
    border: none;
    background-color: transparent;
  }
  
  .pref:hover {
    color: rgb(156 163 175);
  }
  
  .pref:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  
  .accept {
    font-size: 0.75rem;
    line-height: 1rem;
    background-color: rgb(17 24 39);
    font-weight: 500;
    border-radius: 0.5rem;
    color: #fff;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    border: none;
    transition: all .15s cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .accept:hover {
    background-color: rgb(55 65 81);
  }
  
  .accept:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  

  /* cookie policy start */
  .unique-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Changed to min-height for better responsiveness */
    margin: 20px;
  }
  
  .unique-card {
    width: 100%;
    max-width: 800px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .unique-heading,
  .unique-subheading {
    color: #333;
  }
  
  .unique-list {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .unique-link {
    color: #007bff;
  }
  
  .unique-last-updated {
    font-style: italic;
  }
  
  /* Responsive styles */
  @media screen and (max-width: 768px) {
    .unique-card {
      padding: 10px; /* Adjust padding for smaller screens */
    }
  }
  
  @media screen and (max-width: 576px) {
    .unique-container {
      align-items: flex-start; /* Align items to the start on smaller screens */
    }
  
    .unique-card {
      border-radius: 0; /* Remove border radius for smaller screens */
      box-shadow: none; /* Remove box shadow for smaller screens */
    }
  }
  
  /* cookie policy end */