.headerrrrrr {
	position: fixed;
    background: #ffffff;
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
	box-shadow: 0px 15px 38px -10px rgba(0,0,0,0.1);
  z-index: 9999;
}
.headerrrrrr .logo {
    font-size: 30px;
    text-transform: uppercase;
    color: #000;
}
.Imghg img {
  height: 50px;
}
.headerrrrrr .navvav ul {
    display: flex;
	flex-direction: row;
}
.headerrrrrr .navvav ul li a {
    display: inline-block;
    color: #000;
    padding: 5px 0;
    margin: 0 10px;
    border: 3px solid transparent;
    text-transform: uppercase;
    transition: 0.2s;
}
.headerrrrrr .navvav ul li a:hover{
    color: #ffc506;
}
.hamburger {
    cursor: pointer;
    display: none;
}
.hamburger div {
    width: 30px;
    height: 3px;
    margin: 5px 0;
    background: #000;
}
@media only screen and (max-width: 900px) {
    .headerrrrrr {
        padding: 0 30px;
    }
}
@media only screen and (max-width: 700px) {
    .hamburger {
        display: block;
      
    }
    .headerrrrrr .navvav {
        position: absolute;
        width: 100%;
        left: -100%;
        top: 70px;
        width: 100%;
        background: #fff;
        padding: 30px;
        transition: 0.3s;
        
    }
    .headerrrrrr #nav_check:checked ~ .navvav {
        left: 0;
    }
    .headerrrrrr .navvav ul {
        display: block;
    }
    .headerrrrrr .navvav ul li a {
        margin: 5px 0;
    
    }
}

.butointini {
	position: relative;
	display: inline-block;
	margin: 15px;
	padding: 10px 15px;
	text-align: center;
	font-size: 18px;
	letter-spacing: 1px;
	text-decoration: none;
	color: #000000;
	background: transparent;
	cursor: pointer;
	transition: ease-out 0.5s;
	border: 2px solid #ffc506;
	border-radius: 10px;
	box-shadow: inset 0 0 0 0 #ffc506;
  }
  .butointini i{
	position: relative;
	top: 5px;
	left: -5px;
  }

  .butointini:hover {
	color: white;
	box-shadow: inset 0 -100px 0 0 #ffc506;
  } 
 
  .butointini:active {
	transform: scale(0.9);
  }

  @media (min-width: 481px) and (max-width: 1280px){
	.hamburger {
        display: block;
    }
    .headerrrrrr .navvav {
        position: absolute;
        width: 100%;
        left: -100%;
        top: 70px;
        width: 100%;
        background: #fff;
        padding: 30px;
        transition: 0.3s;
    }
    .headerrrrrr #nav_check:checked ~ .navvav {
        left: 0;
        text-align: center;
    }
    .headerrrrrr .navvav ul {
        display: block;
    }
    .headerrrrrr .navvav ul li a {
        margin: 5px 0;
    }
   
}
.sub-menu {
	display: none;
	position: absolute;
	background-color: #fff;
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
	z-index: 1;
	width: 600px;
  margin-left: -210px;
	text-align: center; /* Center align the submenu */
  height: 200px;
  }
  
  .menu-item-has-children:hover .sub-menu {
	display: block;
  }
  
  .mega-menu {
	display: flex; 
  flex-direction: row;
  }
  
  .mega-menu-column {
	display: inline-block; /* Display columns inline */
	vertical-align: top; /* Align columns to the top */
	width: 100%; /* Set width to 50% for two columns */
  }
  
  .mega-menu-column ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
  }
 
  .mega-menu-column ul li a {
	color: #000;
	text-decoration: none;
  }
  .mega-menu-column ul li a:hover {
	color: #ffc506;
  }

  @media (min-width: 481px) and (max-width: 1280px){
    .sub-menu {
      display: none;
      position: absolute;
      background-color: #fff;
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
      z-index: 1;
      width: 600px;
      text-align: center; /* Center align the submenu */
      height: 200px;
      margin-left: 90px;
      }
  }
  
  @media only screen  and (max-width: 758px){
    .sub-menu {
      display: none;
      position: absolute;
      background-color: #fff;
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
      z-index: 1;
      width: 300px;
      text-align: center; /* Center align the submenu */
      height: 300px;
      margin-left: 10px;
      }
      .butointini {
        position: relative;
        display: inline-block;
        /* padding: 10px 15px; */
        text-align: center;
        font-size: 14px;
        text-decoration: none;
        color: #000000;
        background: transparent;
        cursor: pointer;
        transition: ease-out 0.5s;
        border: 2px solid #ffc506;
        border-radius: 10px;
        box-shadow: inset 0 0 0 0 #ffc506;
        height: 50px;
        
        }
        .butointini i{
          position: relative;
          top: 2px;
          left: -15px;
          }
          .namensmae{
            position: relative;
            top: -12px;
            text-align: center;
          }
          .namensmaee{
            position: relative;
            top: -5px;
            left: 16px;
          }
}
@media only screen  and (max-width: 767px){
  .sub-menu {
    display: none;
    position: absolute;
    background-color: #fff;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    width: 300px;
    text-align: center; /* Center align the submenu */
    height: 300px;
    margin-left: 10px;
    }
    .butointini {
      position: relative;
      display: inline-block;
      /* padding: 10px 15px; */
      text-align: center;
      font-size: 14px;
      text-decoration: none;
      color: #000000;
      background: transparent;
      cursor: pointer;
      transition: ease-out 0.5s;
      border: 2px solid #ffc506;
      border-radius: 10px;
      box-shadow: inset 0 0 0 0 #ffc506;
      height: 50px;
      
      }
      .butointini i{
        position: relative;
        top: px;
        left: -1px;
        }
        .namensmae{
          position: relative;
          top: -8px;
          text-align: center;
        }
        .namensmaee{
          position: relative;
          top:0px;
          left: 3px;
        }
        .headerrrrrr .logo {
          font-size:16px;
          text-transform: uppercase;
        }
}
