.navbar-container {
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    z-index: 999;
    mix-blend-mode: difference;
  }
   
  .navbar-container hr {
    width: 100px;
    margin: 5px 0;
    transition: width 0.3s;
    opacity: 100;
    background-color: white;
    color: white;
    mix-blend-mode: difference;
  }
   
  .navbar-container p {
    margin: 0;
    cursor: pointer;
    color: white;
    transition: color 0.3s;
    font-size: 20px;
    mix-blend-mode: difference;
  }
   
  .navbar-container .active {
    color: white;
  }
   
  .navbar-container .active + hr {
    width: 130px;
  }

  @media (300px <= width <= 600px) {
    .navbar-container hr {
      width: 50px;
    }
    .navbar-container p {
      font-size: 14px;
    }
    .navbar-container .active + hr {
      width: 80px;
    }
  }

/* nav bar lines start */

/* nav bar lines end */