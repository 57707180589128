*{
    margin: 0px;
}
body{
  /* font-family: "Roboto",'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
  font-family: "anta";
  overflow-x: hidden;
}

.home-video video{
    object-fit: cover;
    width: 100%;
    height: 80vh;
    /* filter: blur(2px); */
}
.main-heading{
    position: absolute;
    width: 100%;
    height: 100%; 
    top:0;
    color: aliceblue;
    
}
.main-heading h1{
    font-size: 3em;
    padding: 5vh;
    font-size: 28px;
    font-weight: 600;
}
.main-sub-heading{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    color: #ffffff;
}
.main-sub-heading p{
    font-size: 18px;
    animation: p 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    color: #ffc506;
}
@keyframes p{
  0% {
    letter-spacing: 1em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}


.main-sub-heading h2{
    font-size: 57px;
}
/* main-heading start */
.ml6 {
  position: relative;
  font-weight: 900;
  font-size: 3.3em;
}

.ml6 .text-wrapper {
  position: relative;
  display: inline-block;
  padding-top: 0.2em;
  padding-right: 0.05em;
  padding-bottom: 0.1em;
  overflow: hidden;
}

.letters {
  -webkit-animation: letters 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: letters 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes letters {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
            transform: translateZ(300px);
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(12px);
            transform: translateZ(12px);
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@keyframes letters {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
            transform: translateZ(300px);
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(12px);
            transform: translateZ(12px);
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
/* main-heading end */
/* button start */
/* .btn {
    font-size: 17px;
    background: transparent;
    border: none;
    padding: 16px;
    color: #ffedd3;
    text-transform: uppercase;
    position: relative;
    transition: .5s ease;
    margin-top: 5px;
  
  }
  
  .btn::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 0;
    background-color: #ffc506;
    transition: .5s ease;
  }
  
  .btn:hover {
    color: #1e1e2b;
    transition-delay: .5s;
  }
  
  .btn:hover::before {
    width: 100%;
  }
  
  .btn::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 0;
    width: 100%;
    background-color: #ffc506;
    transition: .4s ease;
    z-index: -1;
  }
  
  .btn:hover::after {
    height: 100%;
    transition-delay: 0.4s;
    color: aliceblue;
  } */
  .c-button {
    color: #ffffff;
    font-weight: 700;
    font-size: 14px;
    text-decoration: none;
    padding: 10px;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    z-index: 1;
  }
  
  .c-button--gooey {
    background-color: #000;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 2px;
    border: 4px solid #ffc506;
    border-radius: 0px;
    position: relative;
    transition: all 700ms ease;
  }
  
  .c-button--gooey .c-button__blobs {
    height: 100%;
    filter: url(#goo);
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    bottom: -3px;
    right: -1px;
    z-index: -1;
  }
  
  .c-button--gooey .c-button__blobs div {
    background-color: #ffc506;
    width: 34%;
    height: 100%;
    border-radius: 100%;
    position: absolute;
    transform: scale(1.4) translateY(125%) translateZ(0);
    transition: all 700ms ease;
  }
  
  .c-button--gooey .c-button__blobs div:nth-child(1) {
    left: -5%;
  }
  
  .c-button--gooey .c-button__blobs div:nth-child(2) {
    left: 30%;
    transition-delay: 60ms;
  }
  
  .c-button--gooey .c-button__blobs div:nth-child(3) {
    left: 66%;
    transition-delay: 25ms;
  }
  
  .c-button--gooey:hover {
    color: #000000;
  }
  
  .c-button--gooey:hover .c-button__blobs div {

    transform: scale(1.4) translateY(0) translateZ(0);
  }
/* button end */
@media (max-width: 768px) {
  .main-heading h1 {
    font-size: 24px;
  }

  .main-sub-heading h2 {
    font-size: 40px;
  }

  .btn {
    font-size: 14px;
  }
  
}
@media only screen  and (max-width: 768px){
  .ml6 .letters{
    font-size: 24px;
  }
  .main-sub-heading p{
    font-size: 20px;
    animation: p 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    color: #ffc506;
}
}
@media (min-width: 481px) and (max-width: 1280px){
  .main-sub-heading p{
    font-size: 28px;
    animation: p 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    color: #ffc506;
}
.ml6 .letters{
  font-size: 58px;
}
}

/* cards start */
.cards {
  width: 1300px;
  height: 300px;
  background: #FFF;
  padding: 0 40px;
  box-sizing: border-box;
  margin: 15px;
  margin-left: 80px;
}

.card-single {
  width:100%;
  padding: 20px;
  background: #FFF;
  font-family: 'Nunito', sans-serif;
  transition: ease all 600ms; 
  position:relative;
}

.card-single:hover {
  background: #FFF;
  box-shadow: 0px 0px 10px #000000ad;
  z-index: 3;
}

.service-desc  {
  display: block;
}

.card-single h3 {
  text-align: center;
  font-size: 26px;
}

.icon-wrapper {
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
} 
.icon-wrapper:hover{
  display: none;
}

/* 
.card-single img{
width:50px;
height:50px;
display:block;
margin-bottom:15px;
transition:ease all 600ms
} */

/* .card-single:hover .service-desc {
  top: -125px;
  visibility: visible;
  opacity: 1;
}

.card-single:hover .icon-wrapper {
  top: 30px;
} */.servicepad{
  padding-top: 100px;
}
.cardrow{
  padding: 10px;

}
.contininin{
  display: flex;
  justify-content: center;
 
}
.card {
  width: 300px;
  height: 200px;
  perspective: 1000px;
  margin: 13px;
  border-style: none;
}

.card-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.999s;
  
}

.card:hover .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  padding: 15px;

}
.containererere{
  margin-top: 40px;
}
.card-front {
  color: rgb(0, 0, 0);
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  font-size: 24px;
  transform: rotateY(0deg);
  border-color: #ffc506;
  border-style: solid;
  border-width: 2px;
 
}
.card-front p{
  padding-top:15px ;
}
.card-back {
  background-color: #000000;
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  font-size: 24px;
  transform: rotateY(180deg);
  border-color: #ffc506;
  border-style: solid;
  border-width: 2px;
  text-align: center;
}

.iconsss{
  size: 55px;
}
.card-back h6{
  text-decoration:underline;
}
.card-back h6:hover{
  font-weight: bolder;
}
.callbutton{
  margin-left: -120px;
}
@media (300px <= width <= 600px) {
  .card {
    width: 300px;
    height: 150px;
    perspective: 1000px;
    margin: 15px;
    border-style: none;
  }
  .contininin{
    margin-left:20px ;
  }
}
@media (min-width: 481px) and (max-width: 1280px){
  .contininin{
    margin-left:20px ;
  }
}
/* cards end*/

/* second video start */
.home-video1 video{
  margin-top: 10px;
  object-fit: cover;
  width: 100%;
  height: 80vh;
  /* filter: blur(2px); */
}
/* second video end */

.img-view img{

  height: 400px;
  width: 420px;
  border-radius: 50px;

}
.img-view{
    top: 1000px;
    left: 120px;
    position: absolute;
    
}

.sub-heading{
  top:1020px;
  left: 720px;
  position: absolute;
  color: white;
}
.sub-heading h3{
  font-size: 38px;
  font-weight: 800;
}
.sub-heading-icon i{
  margin: 10px;
}
.sub-heading-icon1 i{
  margin: 10px;
  margin-left: 10px;
}


/* about us start  */

.responsive-container-block {
  min-height: 75px;
  height: fit-content;
  width: 100%;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  justify-content: flex-start;
}

a {
  text-decoration-line: none;
  text-decoration-thickness: initial;
  text-decoration-style: initial;
  text-decoration-color: initial;
}

.text-blk {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  line-height: 25px;
}

.responsive-container-block.bigContainer {
  padding-top: 10px;
  padding-right: 30px;
  padding-bottom: 10px;
  padding-left: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 50px 10px 50px;
}

.mainImg {
  color: black;
  width: 100%;
  height: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.text-blk.headingText {
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  color: #000000;
  padding-top: 0px;
  padding-right: 10px;
  padding-bottom: 0px;
  padding-left: 0px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 5px;
  margin-left: 0px;
  
}
.headingText1{
  color: #ffc506;

}
.allText {
 
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  width: 40%;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}

.text-blk.subHeadingText {
  color: rgb(102, 102, 102);
  font-size: 26px;
  line-height: 32px;
  font-weight: 700;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 15px;
  margin-left: 0px;
  padding-top: 0px;
  padding-right: 10px;
  padding-bottom: 0px;
  padding-left: 0px;
  
}

.text-blk.description {
  font-size: 18px;
  line-height: 26px;
  color: rgb(102, 102, 102);
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 50px;
  margin-left: 0px;
  font-weight: 400;
  padding-top: 0px;
  padding-right: 10px;
  padding-bottom: 0px;
  padding-left: 0px;
}

.explore {
  font-size: 16px;
  line-height: 28px;
  color: rgb(102, 102, 102);
  border-top-width: 2px;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-color: rgb(102, 102, 102);
  border-right-color: rgb(102, 102, 102);
  border-bottom-color: rgb(102, 102, 102);
  border-left-color: rgb(102, 102, 102);
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  cursor: pointer;
  background-color: white;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-top: 8px;
  padding-right: 40px;
  padding-bottom: 8px;
  padding-left: 40px;
}

.explore:hover {
  background-image: initial;
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-repeat-x: initial;
  background-repeat-y: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: #ffc506;
  color: rgb(0, 0, 0);
  border-top-width: initial;
  border-right-width: initial;
  border-bottom-width: initial;
  border-left-width: initial;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: none;
  border-left-style: none;
  border-top-color: initial;
  border-right-color: initial;
  border-bottom-color: initial;
  border-left-color: initial;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
}

.responsive-container-block.Container {
  margin-top: 80px;
  margin-right: auto;
  margin-bottom: 50px;
  margin-left: auto;
  justify-content: center;
  align-items: center;
  max-width: 1320px;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}

.responsive-container-block.Container.bottomContainer {
  flex-direction: row-reverse;
  margin-top: 80px;
  margin-right: auto;
  margin-bottom: 50px;
  margin-left: auto;
  position: static;
}

.allText.aboveText {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 40px;
}

.allText.bottomText {
  margin-top: 0px;
  margin-right: 40px;
  margin-bottom: 0px;
  margin-left: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 0px;
  padding-right: 15px;
  padding-bottom: 0px;
  padding-left: 0px;
}

.purpleBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 430px;
  background-color: #ffc506;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
  bottom: -35px;
  left: -8%;
}

.purpleText {
  font-size: 18px;
  line-height: 26px;
  color: white;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 10px;
  margin-left: 0px;
}

.ultimateImg {
  width: 50%;
  position: relative;
}

@media (max-width: 1024px) {
  .responsive-container-block.Container {
    max-width: 850px;
  }

  .mainImg {
    width: 55%;
    height: auto;
  }

  .allText {
    width: 40%;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 20px;
  }

  .responsive-container-block.bigContainer {
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
  }

  .responsive-container-block.Container.bottomContainer {
    margin-top: 80px;
    margin-right: auto;
    margin-bottom: 50px;
    margin-left: auto;
  }

  .responsive-container-block.Container {
    max-width: 830px;
  }

  .allText.aboveText {
    margin-top: 30px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 40px;
  }

  .allText.bottomText {
    margin-top: 30px;
    margin-right: 40px;
    margin-bottom: 0px;
    margin-left: 0px;
    text-align: left;
  }

  .text-blk.headingText {
    text-align: center;
  }

  .allText.aboveText {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    

  }

  .text-blk.subHeadingText {
    text-align: left;
    font-size: 26px;
    line-height: 32px;
  }

  .text-blk.description {
    text-align: left;
    line-height: 24px;
  }

  .explore {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }

  .responsive-container-block.bigContainer {
    padding-top: 10px;
    padding-right: 30px;
    padding-bottom: 10px;
    padding-left: 30px;
  }

  .responsive-container-block.Container {
    justify-content: space-evenly;
  }

  .purpleBox {
    bottom: 10%;
  }

  .responsive-container-block.Container.bottomContainer {
    padding-top: 10px;
    padding-right: 0px;
    padding-bottom: 10px;
    padding-left: 0px;
    max-width: 930px;
  }

  .allText.bottomText {
    width: 40%;
  }

  .purpleBox {
    bottom: auto;
    left: -10%;
    top: 70%;
  }

  .mainImg {
    width: 100%;
  }

  .text-blk.headingText {
    text-align: left;
  }
}

@media (max-width: 768px) {
  .allText {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    
  }

  .responsive-container-block.Container {
    flex-direction: column;
    height: auto;
  }

  .text-blk.headingText {
    text-align: center;
  }

  .text-blk.subHeadingText {
    text-align: center;
    font-size: 24px;
  }

  .text-blk.description {
    text-align: center;
    font-size: 18px;
  }

  .allText {
    margin-top: 40px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }

  .allText.aboveText {
    margin-top: 40px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }

  .responsive-container-block.Container {
    margin-top: 80px;
    margin-right: auto;
    margin-bottom: 50px;
    margin-left: auto;
  }

  .responsive-container-block.Container.bottomContainer {
    margin-top: 50px;
    margin-right: auto;
    margin-bottom: 50px;
    margin-left: auto;
  }

  .allText.bottomText {
    margin-top: 40px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }

  .mainImg {
    width: 100%;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: -70px;
    margin-left: 0px;
  }

  .responsive-container-block.Container.bottomContainer {
    flex-direction: column;
  }

  .ultimateImg {
    width: 100%;
  }

  .purpleBox {
    position: static;
  }

  .allText.bottomText {
    width: 100%;
    align-items: center;
  }

  .text-blk.headingText {
    text-align: center;
  }

  .text-blk.subHeadingText {
    text-align: center;
  }

  .text-blk.description {
    text-align: center;
  }

  .ultimateImg {
    position: static;
  }

  .mainImg {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }

  .ultimateImg {
    position: relative;
  }

  .purpleBox {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    position: absolute;
    left: 0px;
    top: 80%;
  }

  .allText.bottomText {
    margin-top: 100px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
}

@media (max-width: 500px) {
  .responsive-container-block.Container {
    padding-top: 10px;
    padding-right: 0px;
    padding-bottom: 10px;
    padding-left: 0px;
    width: 100%;
    max-width: 100%;
  }

  .mainImg {
    width: 100%;
  }

  .responsive-container-block.bigContainer {
    padding-top: 10px;
    padding-right: 25px;
    padding-bottom: 10px;
    padding-left: 25px;
  }

  .text-blk.subHeadingText {
    font-size: 24px;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    line-height: 28px;
  }

  .text-blk.description {
    font-size: 16px;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    line-height: 22px;
  }

  .allText {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    width: 100%;
  }

  .allText.bottomText {
    margin-top: 50px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding: 0 0 0 0;
    margin: 30px 0 0 0;
  }

  .ultimateImg {
    position: static;
  }

  .purpleBox {
    position: static;
  }

  .stars {
    width: 55%;
  }

  .allText.bottomText {
    margin-top: 75px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }

  .responsive-container-block.bigContainer {
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
  }

  .purpleText {
    font-size: 16px;
    line-height: 22px;
  }

  .explore {
    padding: 6px 35px 6px 35px;
    font-size: 15px;
  }
}

/* about us end  */

/* counting up start */
.course-title {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 34px;
  color: #fff;
}

  .count-up {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap:wrap;
  width:100vw;
  height:50vh;
  
}
  .wrapper {
    display: flex;
  justify-content: center;
    align-items: center;
    flex-direction:column;
  position: relative;
  margin:  10px;
  box-shadow: -4px 2px 10px 0 rgba(0, 0, 0, 0.2);        
 background-color:#fff;
  width:300px;
    height:300px;
}
  .counter {
  font-size: 40px;
  font-weight:bold;
  background-color: #fff;
  border-radius: 3px;
  position: relative;
  color:#3e3e3e;
}
  .wrapper i {
  color: #17a2b8;
  z-index: 1;
  font-size: 64px;
 margin-bottom:5px
}

.social-container {
  margin: 20px 0;
display:flex;
justify-content:center;
}
.social-container a {
  border: 1px solid #ddd;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 0 5px;
  display: inline-flex;
  justify-content: center;
text-decoration:none;
  align-items: center;

}
.social-container a:hover{
text-decoration:none;
}
.social-container a:hover i{
font-size:20px;
}
.social-container a i{
color:#3e3e3e;
transition:all 0.5s;
}
h5{
  font-size: 48px;
}
/* counting up end */

/* seervice start */
/* .card1 {
  width: 1110px;
  height: 354px;
  border-radius: 4px;
  background: #ffffff;
  gap: 5px;
  padding: .4em;
}

.card1 p {
  
  height: 100%;
  flex: 1;
  overflow: hidden;
  cursor: pointer;
  border-radius: 2px;
  transition: all .5s;
  background: #ffffff;
  border: 1px solid #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card1 p:hover {
  flex: 50;

}

.card1 p span {
  min-width: 14em;
  padding: .5em;
  text-align: center;
  transform: rotate(-90deg);
  transition: all .5s;
  text-transform: uppercase;
  color: #000000;
  letter-spacing: .1em;
  
}

.card1 p:hover span {
  transform: rotate(0);
  display: none;
} */

.card1 {
  width: 1110px;
  height: 354px;
  border-radius: 4px;
  background: #ffffff;
  gap: 5px;
  padding: .4em;
}

.card1 p {
  height: 100%;
  flex: 1;
  overflow: hidden;
  cursor: pointer;
  border-radius: 2px;
  transition: all .5s;
  background: #ffffff;
  border: 1px solid #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.card1 p:hover {
  flex: 50;
}

.card1 p span {
  min-width: 14em;
  padding: .5em;
  text-align: center;
  transform: rotate(-90deg);
  transition: all .5s;
  text-transform: uppercase;
  color: #000000;
  letter-spacing: .1em;
  position: relative;
  z-index: 1;
}

.card1 p:hover span {
  transform: rotate(0);
}

.card1 p span::before {
  content: attr(data-default-content); /* Set your default content here */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotate(0);
  text-align: center;
  transition: all .5s;
  z-index: 0;
}

.card1 p:hover span::before {
  content: attr(data-hover);
  transform: rotate(0);
}



/* .text-cards{
   display: none;
}
.text-cards:hover{
  display: block;
  
} */
/* seervice end */

/* contact us from start */
.contact {
  padding: 10px 0;
}
@media only screen and (max-width: 980px) {
  .contact{
    margin-top: 150px;
  }
  
}
@media only screen and (max-width: 640px) {
  .contact{
    margin-top: 600px;
  }
}
.contact .heading h2 {
  font-size: 30px;
  font-weight: 700;
  margin: 0;
  padding: 0;

}

.contact .heading h2 span {
  color: #ffc506;
}

.contact .heading p {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.7;
  color: #999999;
  /* margin: 20px 0 60px; */
  padding: 0;
}

.contact .form-control {
  padding: 25px;
  font-size: 13px;
  margin-bottom: 10px;
  background: #f9f9f9;
  border: 0;
  border-radius: 10px;
}

.contact .title h3 {
  font-size: 18px;
  font-weight: 600;

}

.contact .title p {
  font-size: 14px;
  font-weight: 400;
  color: #999;
  line-height: 1.6;
  margin: 0 0 40px;
 
}

.contact .content .info {
  margin-top:20px;
}
.contact .content .info i {
  font-size: 30px;
  padding: 0;
  margin: 0;
  color: #02434b;
  margin-right: 20px;
  text-align: center;
  width: 20px;
}
.contact .content .info h4 {
  font-size: 13px;
  line-height: 1.4;
}

.contact .content .info h4 span {
  font-size: 13px;
  font-weight: 300;
  color: #999999;
}

.buttonon {
  appearance: none;
  background-color: transparent;
  border: 0.125em solid #1A1A1A;
  border-radius: 0.9375em;
  box-sizing: border-box;
  color: #3B3B3B;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 3.75em;
  min-width: 0;
  outline: none;
  padding: 1em 2.3em;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
 }
 .buttononon{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
 }
 .buttonon:disabled {
  pointer-events: none;
 }
 
 .buttonon:hover {
  color: #fff;
  background-color: #1A1A1A;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
 }
 
 .buttonon:active {
  box-shadow: none;
  transform: translateY(0);
 }
/* contact us from end */

/* client says start */
 .sectionmkjk {
	display: grid;
	place-items: center;
	height: 80vh;
}

.section-header{
    position: relative;
    text-align: center;
    font-weight: 900;
    color: #000000;
    margin-top: 120px;
}
.section-header:after{
    content: ' ';
    height: 3px;
    width: 200px;
    position: absolute;
    bottom: 0;
    left: calc(50% - 100px);
    background: #ffffff;
}
.containerrr .section-header span{
  color: #ffc506;
}
.testimonials{
    max-width: 1000px;
    /* padding: 0 15px 50px;
    margin: 0 auto 80px auto; */
}
.single-item{
    background: #fff;
    color: #111;
    padding: 15px;
    /* margin: 50px 15px; */
}
.profile{
    margin-bottom: 30px;
    text-align: center;    
}
.img-area {
	margin: 0 15px 15px 15px;
}
.img-area img {
	height: 200px;
	width: 200px;
	border-radius: 50%;
	border: 7px solid #ffc506;
}
.content{
    font-size: 18px;
}
.content .p1 {
	text-align: justify;
}
.content .p1 span {
	font-size: 48px;
	margin-right: 20px;
    color: #ffc506;
}
.socials i {
	margin-right: 25px;
}
.bio h4 {
	font-family: 'Berkshire Swash', cursive;
}
.carousel-control-next-icon, .carousel-control-prev-icon {
	background-color: #ffc506;
} 

@media only screen  and (max-width: 768px){
  .sectionmkjk {
    display: grid;
    place-items: center;
    height: 130vh;
  }
  .contact .title h3 { 
    text-align: center;
  }
  .contact .title p {
    text-align: center;
  }
  
}
@media (min-width: 481px) and (max-width: 1280px){
  .sectionmkjk {
    display: grid;
    place-items: center;
    height: 62vh;
  }
  
}

/* client says end */


/* about uss new add start  */
.bodyduyuhd{
  display: grid;
  background-image: url(../img/digi.jpg);
  background-position: center;
  align-content: center;
  min-height: 100vh;
}
.section-title{
  text-align: center;
}
.section{
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 70vh;
  width: 75vw;
  margin: 0 auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.055), 0 6px 20px 0 rgba(0, 0, 0, 0);
  border-radius: 12px;
}
.image-container{
  display: flex;
  border-radius: none;
  border-width: 0;
}
.image-container img{
  height: 60vh;
  margin: 50px auto;
}
.content-container{
  backdrop-filter: blur(4px) saturate(0%);
  -webkit-backdrop-filter: blur(4px) saturate(0%);
  background-color: rgb(255, 255, 255);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: none;
  color: #000000;
  border-width: 0;
  height: 680px;
}
.content-container .section-title{
  margin-top: 60px;
  text-transform: uppercase;
  font-size: 36px;
  letter-spacing: 6px;
  opacity: 0.9;
}
.content-container span{
  height: 0.5px;
  width: 80px;
  background: #000000;
  margin: 30px 0;
}
.content-container p{
  padding-bottom: 15px;
  font-weight: 300;
  opacity: 0.7;
  width: 60%;
  text-align: center;
  margin: 0 auto;
  line-height: 1.7;
  color: #000000;
}
.navigation-links{
  margin: 15px 0;
}
.navigation-links li{
  border: 2px solid #000000;
  list-style: none;
  border-radius: 5px;
  padding: 10px 15px;
  width: 160px;
  text-align: center;
}
.navigation-links li a{
  text-transform: uppercase;
  color: #000000;
  text-decoration: none;
}
.navigation-links li:hover{
  border-color: #c8ac50;
}

.divider {
  height: 30px;
  width: 3px;
  background: #ffc711;
  margin: auto;
  margin-top: 10px;
  animation: flowLine 1.5s infinite linear; /* Adjust animation duration and timing function as needed */
}

/* Keyframes for the animation */
@keyframes flowLine {
  0% {
    transform: scaleY(0);
  }
  50% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
}
.social-icons{
  display: flex;
  padding: 15px 0;
}
.social-icons li{
  display: block;
  padding: 5px;
  margin: 5px;
}
.social-icons li i{
  font-size: 26px;
  opacity: 0.8;
}
.social-icons li i:hover{
  color: #C850C0;
  cursor: pointer;
}


/*****************/

@media(max-width: 900px){
  .section{
      grid-template-columns: 1fr;
      width: 100%;
      border-radius: none;
  }
  .image-container{
      height: 80vh;
      border-radius: none;
  }
  .image-container img{
      height: 300px;
      border-radius: none;
  }
  .content-container{
      height: 90vh;
      margin-top: -180px;
      border-radius: none;
  }
  .content-container .section-title{
      font-size: 20px;
      margin-top: 50px;
      font-weight: bold;
  }
  .content-container span{
      margin: 20px 0;
  }
  .content-container p{
      font-size: 16px;
      font-weight: 500;
  }
  .navigation-links li a{
      font-size: 14px;
  }
  .navigation-links{
      margin: 5px 0;
  }
  .navigation-links li{
      padding: 6px 10px;
  }
  .social-icons li i{
      font-size: 15px;
  }
}
@media (min-width: 481px) and (max-width: 1208px){
  .image-container{
    height: 80vh;
    border-radius: none;
}
.image-container img{
    height: 500px;
    border-radius: none;
}
.content-container{
    height: 70vh;
    margin-top: -180px;
    border-radius: none;
}
.content-container .section-title{
  font-size: 28px;
  margin-top: 0px;
  font-weight: bold;
}
.content-container span{
  margin: 20px 0;
}
.content-container p{
  font-size: 24px;
  font-weight: 500;
}
}
/* about uss new add end  */



/* servicemain page start */
.wokkkk{
  border-width: 0px;
  height: auto;
  border-radius: none;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  /* font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
  width:30px;
  color:rgb(0, 0, 0);
  margin-left: -10px;
}

.callcolror{
  color: black;
  margin-left: -70px;
}
.callbutton {
  margin-left: -260px;

}
/* .wok:hover{
  transform: scale(1.1);
  background-color: rgb(252, 248, 24);
} */

.rkkkk img{
  width: 120px;
  margin-left: 10px;
}
@media only screen  and (max-width: 768px){
  .rkkkk img{
      width: 100px;
      height: 100px;
  }
}
@keyframes sparkle {
  0% { transform: scale(1); opacity: 1; }
  100% { transform: scale(2); opacity: 0; }
}

.sparkle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-radius: 50%;
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  animation: sparkle 1s ease-out;
}


/* servicemain page end */



/* sericesubpage start */

/* navlink css start */
.nav-link {
  color: rgb(0, 0, 0); /* Text color */
  background-color: rgb(255, 255, 255); /* Text background color */
  border: 2px solid rgb(0, 0, 0); /* Border color */
  border-radius: 0;
  margin: 0;
}

/* Styling for the tab content */
.tab-content {
 
  margin-top: 20px; /* Adjust margin as needed */
}

/* Styling for individual tab panes */
.tab-pane {
  background-color: #ffffff; /* Light gray */
  padding: 20px;
}


/* Optional: Add transition effect */
.nav-link {
  transition: background-color 0.3s ease, color 0.3s ease;
}
/* navlink css end */
.pic{
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 20px;
}
.textt{
  font-size: 4vh;
  font-weight: 600;
  margin-left: 80px;
}
.emp{
  color: #ffc506c5;
}
.poe{
  margin: 95px;
}
.pic1{
  width: 100%;
  max-width: 1000px;
  height: auto;
  border-radius: 20px;
}
.piccaca1{
  width: 100%;
  max-width:1800px;
  height: auto;
  border-radius: 20px;
}
.work{
  font-size: 3vh;
  font-weight: 600;
}
.emp1{
  font-size: 4vh;
  font-weight: 600;
  color: #ffc506c5;
}
.minn{
  margin: 50px;
}
.pic11{
  width: 100%;
  max-width: 700px;
  height: auto;
  margin-top: 60px;
  border-radius: 20px;
}
.pic1122{
  width: 100%;
  max-width: 600px;
  height: auto;
  margin-top: 60px;
  border-radius: 20px;
  margin-left:100px;
}
.tonn{
  margin-top: 100px;
}
.empppp{
  font-family: "anta";
  margin-top: 50px;
}
.emppppp {
  margin-top: 50px;
}

@media only screen  and (max-width: 768px){
  .pic{
    width: 100%;
    max-width: 300px;
    height: auto;
  }
  .textt{
    font-size: 2vh;
    font-weight: 600;
    margin-left: 20px;
  }
  .emp{
    color: #ffc506c5;
  }
  .poe{
    margin: 0px;
  }
  .pic1{
    width: 100%;
    max-width: 1000px;
    height: auto;
    margin-top: 60px;
  }
  .pic11{
    width: 100%;
    max-width: 700px;
    height: auto;
    margin-top: 60px;
  }
  .work{
    font-size: 12px;
    font-weight: 600;
  }
  .emp1{
    font-size: 2vh;
    font-weight: 600;
    color: #ffc506c5;
  }
  .minn{
    margin: 0px;
  }
  .tonn{
    margin-top: 70px;
  }
  .workk{
    font-size: 8px;
    font-weight: 600;
  }
  .pic111{
    width: 100%;
    max-width: 200px;
    height: auto;
    margin-top: 120px;
  }
  .pic1122{
    width: 100%;
    max-width: 600px;
    height: auto;
    margin-top: 60px;
    border-radius: 20px;
    margin-left:20px;
  }
  .piccaca1{
    width: 100%;
    max-width:1000px;
    height: auto;
    border-radius: 20px;
    margin-top: 20px;
  }
  .emppppp {
    margin-top: 50px;
  }
}
@media (min-width: 481px) and (max-width: 1280px){
  .pic{
    width: 100%;
    max-width: 400px;
    height: auto;
  }
  .workk{
    font-size: 12px;
    font-weight: 600;
  }
  .pic111{
    width: 100%;
    max-width: 350px;
    height: auto;
    margin-top: 120px;
  }
  .textt{
    font-size: 3vh;
    font-weight: 600;
    margin-left: 80px;
  }
  .emp{
    color: #ffc506c5;
  }
  .poe{
    margin: 30px;
  }
  .pic1{
    width: 100%;
    max-width: 1000px;
    height: auto;
    margin-top: 60px;
  }
  .pic11{
    width: 100%;
    max-width: 700px;
    height: auto;
    margin-top: 60px;
  }
  .work{
    font-size: 18px;
    font-weight: 600;
  }
  .emp1{
    font-size: 3vh;
    font-weight: 600;
    color: #ffc506c5;
  }
  .minn{
    margin: 0px;
  }
  .pic1122{
    width: 100%;
    max-width: 600px;
    height: auto;
    margin-top: 60px;
    border-radius: 20px;
    margin-left:20px;
  }
}

/* sericesubpage end */

/* error page start */

@media only screen and (max-width: 768px){
  .kk{
     font-size: 3vh;
     width: 100%;
    max-width: 400px;
    height: auto;
  }

}
.namename{
  font-weight: 800;
}
/* error page end */
.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #ffd900;
  color: #000000;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, box-shadow 0.3s;
}

.scroll-to-top-button:hover {
  background-color: #000000;
  box-shadow: 0 4px 8px rgba(63, 63, 63, 0.2);
  color: white;
}

/* h1, h2, h3, h4, h5, h6 {
  color: #2c3145;
}
a, a:hover, a:focus, a:active {
  text-decoration: none;
  outline: none;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
} */

.section_all {
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
  min-height: 100vh;
}
.section-title {
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: 1px;
}

.section-subtitle {
  letter-spacing: 0.4px;
  line-height: 28px;
  max-width: 550px;
}

.section-title-border {
  background-color: #000;
  height: 1 3px;
  width: 44px;
}

.section-title-border-white {
  background-color: #fff;
  height: 2px;
  width: 100px;
}
.text_custom {
  color: #ffc506;
  font-size: 28px;
}

.about_icon i {
  font-size: 22px;
  height: 65px;
  width: 65px;
  line-height: 65px;
  display: inline-block;
  background: #fff;
  border-radius: 35px;
  color: #00bd2a;
  box-shadow: 0 8px 20px -2px rgba(158, 152, 153, 0.5);
}

.about_header_main .about_heading {
  max-width: 450px;
  font-size: 18px;
}

.about_icon span {
  position: relative;
  top: -10px;
}

.about_content_box_all {
  padding: 28px;
}





/* itmanagement css start */
.it-management-page {
  font-family: "anta";
  max-width: 1300px;
  margin: 0 auto;
  padding: 10px;
  font-family: Arial, sans-serif;
  color: #333;
}
.itup{
  margin-top: 80px;
}
.itcenter{
  text-align: center;
  font-family: "anta"
}
.sectioononn {
  margin-bottom: 30px;
  font-family: "anta";
  width: 500px;
}
.sectioononn h2{
  font-size: 30px;
}
.sectioononn p{
  font-size: 20px;
  color: #6a6a6a;
}
.sdfsfsf{
  margin-left: 15px;
}
.image-gallery {
  display: flex;
  justify-content: center;
}

.image-gallery img {
  max-width: 100%;
  height: 350px;
  margin-left: 10px;
}
.sectioononnn{
  font-family: "anta";
  width: 100%
}

/* Custom CSS for the cards */
.custom-card {
  border: 1px solid #ccc; /* Light gray border */
  border-radius: 10px; /* Rounded corners */
  transition: box-shadow 0.3s ease;
  height: 100%; /* Ensure the card height takes up available space */
  font-family: "anta";
}

/* Add a shadow effect when hovering over the card */
.custom-card:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

/* Additional styling for the card body */
.card-body {
  text-align: center; /* Center-align the text */
  padding: 20px; /* Add padding */
}

.card-title{
  background-color: #eeeeee;
  padding: 20px;
  font-family: "anta";
}
.yt-custom-card {
  border-radius: 10px; /* Rounded corners */
  padding: 15px; /* Add padding around the card content */
  overflow: hidden; /* Hide any content that overflows from the card */
  transition: box-shadow 0.3s ease;
}

/* Shadow effect on hover */
.yt-custom-card:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

/* Background colors for each card */
.yt-card-color1 {
  background-color: #E0F7FA; /* Light cyan */
}
.yt-card-color2 {
  background-color: #FFF9C4; /* Light yellow */
}
.yt-card-color3 {
  background-color: #E1BEE7; /* Light purple */
}
.yt-card-color4 {
  background-color: #C8E6C9; /* Light green */
}
.yt-card-color5 {
  background-color: #FFCDD2; /* Light red */
}
.yt-card-color6 {
  background-color: #BBDEFB; /* Light blue */
}


/* Styling for the card images */
.card-img-top {
  max-width: 40%; /* Adjust width as necessary */
  max-height: 90px; /* Adjust height as necessary */
  margin: 0 auto; /* Center the image */
}

.why-choose-yatayati-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

/* Styling for each section */
.yt-section {
  width: 100%; /* Adjust the width as needed */
  font-family: "anta";
}
/* Styling for the vertical line */
.vertical-line {
  width: 1px;
  height: 100%;
  background-color: #000000; /* Change color as needed */
  margin: 0 20px; /* Add spacing around the line */
}




.outerdiv
{
	  width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "anta";
}
.innerdiv
{
	transform: scale(0.9);
	display: grid;
	grid-gap: 1.5rem;
	grid-template-rows: repeat(2,22rem);
	grid-template-columns: repeat(4,17rem);
}
.eachdiv
{
	padding: 1rem 2rem;
    border-radius: 0.8rem;
    box-shadow: 5px 5px 20px #6d6b6b6b;
    color: white;
}
.div1
{
	background: #733FC8;
    grid-column: 1/3;
    grid-row: 1/2;
    background-image: url(https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/bg-pattern-quotation.svg);
    background-repeat: no-repeat;
    background-position-x: 25rem;
}
.div2
{
	background:#49556B;
    grid-column: 3/4;
    grid-row: 1/2;
}
.div3
{
	background: white;
    grid-column: 4/5;
    grid-row: 1/3;
    color: black;
}
.div4
{
	background: white;
	grid-column: 1/2;
    grid-row: 2/3;
    color: black;
}
.div5
{
	background: #18202D;
	grid-column: 2/4;
    grid-row: 2/3;
}
.userdetails
{
	display: flex;
}
.imgbox
{
	margin-right: 1rem;
}
.imgbox img
{
	border-radius: 50%;
	width: 2rem;
	border: 2px solid #cec5c5;
}
.detbox
{
	display: flex;
    flex-direction: column;
    justify-content: center;
}
.detbox p
{
	margin: 0;
}
.detbox .name
{
	color: hsl(0, 0%, 81%);
    font-size: 0.9rem;
    margin-bottom: 0.1rem;
    font-weight: 600;
}
.detbox .name.dark
{
	color: #49505A;
}
.detbox .designation
{
	color: hsl(0, 0%, 81%);
    opacity: 50%;
    font-size: 0.8rem;
}
.detbox .designation.dark
{
	color: #49505A;
}
.review h4
{
	font-size: 1.4rem;
	color: #F3DEFF;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 0.8rem;
}
.review.dark h4{
	color:#4B5258;
}
.review p
{
	font-size: 0.95rem;
    color: #F3DEFF;
    font-weight: 500;
    opacity: 50%;
    line-height: 1.5;
}
.review.dark p{
	color: #0e0e0e;
}
.attribution
{
	font-size: 1rem;
    line-height: 1.5;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    text-align: right;
}
.attribution a
{
	text-decoration: none;
}

@media only screen and (max-width: 1000px)
{
	.innerdiv
	{
		transform: scale(0.7);
    
	}
}
@media only screen and (max-width: 800px)
{
	.innerdiv
	{
		transform: scale(0.6);
    margin-top: -120px;
	}
 
}

@media only screen and (max-width: 600px)
{
 
	.div1 {
		background-position-x: 10rem;
	}
	.innerdiv
	{
		display: flex; 
		flex-direction: column;
		transform: scale(1);
		margin: 2rem;
		margin-bottom: 5rem;
	}
	.attribution
	{
		position: relative;
	}
 
}

.cta {
  position: relative;
  margin: auto;
  padding: 12px 18px;
  transition: all 0.2s ease;
  border: none;
  background: none;
  cursor: pointer;
  font-family: "anta";
}

.cta:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 50px;
  background: #b1dae7;
  width: 45px;
  height: 45px;
  transition: all 0.3s ease;
}

.cta span {
  position: relative;
  font-family: "Ubuntu", sans-serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.05em;
  color: #234567;
}

.cta svg {
  position: relative;
  top: 0;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #234567;
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}

.cta:hover:before {
  width: 100%;
  background: #b1dae7;
}

.cta:hover svg {
  transform: translateX(0);
}

.cta:active {
  transform: scale(0.95);
}
@media only screen and (max-width: 768px){
  /* .cta span{
    font-size: 14px;
  }
  .cta {
    width: 100%;
  } */
  .ctsts h1{
    font-size: 18px;
    margin-top:-50px ;
  }
}

/* itmanagement css end  */





.machine-learning-page {
  padding: 20px;

}
.ml-upp{
  margin-top: 50px;
}
.ml-up {
  max-width: 1100px;
  margin: 0 auto;
}

.ml-center {
  text-align: center;
  margin-bottom: 30px;
}

.sectioiopn {
  margin-bottom: 40px;
}

.image-galleryy{
  text-align: center;
}

.image-galleryy img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}



/* Media query for responsiveness */
@media (max-width: 768px) {
  .ml-up {
      padding: 0 20px;
  }
}
















/* DataServices.css */

.data-services-page {
  padding: 20px;
}

.ds-up {
  max-width: 1100px;
  margin: 0 auto;
}
.ds-upp{
  margin-top: 50px;
}
.ds-center {
  text-align: center;
  margin-bottom: 30px;
}

.sectioionn {
  margin-bottom: 40px;
}

.image-gallerry {
  text-align: center;
}

.image-gallerry img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}



/* Media query for responsiveness */
@media (max-width: 768px) {
  .ds-up {
      padding: 0 20px;
  }
}












/* DigitalMarketing.css */

.digital-marketing-page {
  padding: 20px;
}

.dm-up {
  max-width: 1100px;
  margin: 0 auto;
}
.dm-upp{
  margin-top: 50px;
}
.dm-center {
  text-align: center;
  margin-bottom: 30px;
}

.secttion {
  margin-bottom: 40px;
}

.image-galllery {
  text-align: center;
}

.image-galllery img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


/* Media query for responsiveness */
@media (max-width: 768px) {
  .dm-up {
      padding: 0 20px;
  }
}
