
.wok{
    border:1px solid black;
    height: auto;
    border-radius: 20px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    width:150px;
    color:black;
    margin-left: -175px; 
}
/* .wok:hover{
    transform: scale(1.1);
    background-color: rgb(252, 248, 24);
} */
.mamasm{
    margin-top: 30px;
}
.rkrrrr img{
    width: 130px;
}
@media only screen  and (max-width: 768px){
    .rkrrrr img{
        width: 90px;
        height: 90px;
    }
}
.bri{
    font-size:10vw;
    font-family: "Anta";
    font-weight:700;
    font-style: normal;
    text-align: center;
    line-height: 0.9;
}
.bri img{
    width: 40%;
    height: auto;
}
.life{
    color:orange;
    text-align: center;
}
.your{
    text-align: center;
    margin-top:-80px;
}
.yourr{
    margin-top:-70px;
}

.diririr img{
    height: 300px;
}
.dir{
    font-size: 5vh;
    font-family: "Anta";
}
.diridir{
    margin: 50px  ;
}
@media only screen  and (max-width: 768px){
    .diririr img{
        height: 150px;
        margin-left: 50px;
    }
    .dir{
        font-size: 1.5vh;
    }
    .dirr img{
        height: 200px;
        margin-left: 20px;
    }
    .diririr{
        margin: -10px;
    }
    .wok{
        margin-left: -202px;
    }
}
@media (min-width: 481px) and (max-width: 1280px){
    .dir{
        font-size: 2vh;
    }
    .dirr img{
        height: 400px;
    }
    .wok{
        margin-left: -252px;
    }
}

.who{
    font-size: 5vh;
    font-family: "Anta";
    color:orange;
    
}
.teh{
    font-size: 5vh;
    font-family: "Anta";
    
}
@media only screen  and (max-width: 768px){
    .who{
        font-size: 2vh;
        font-family: "Anta";
        color:orange;
        
    }
    .teh{
        font-size: 1.5vh;
        font-family: "Anta";
        
    }
    .tehh li{
        line-height: 0.9;
        font-family: "Anta";
        font-size: 1.5vh;
        text-align: center;
    }
    
}
@media (min-width: 481px) and (max-width: 1280px){
    .who{
        font-size: 3vh;
        font-family: "Anta";
        color:orange;
        
    }
    .teh{
        font-size: 2vh;
        font-family: "Anta";
        
    }
    .tehh li{
        line-height: 0.9;
        font-family: "Anta";
        font-size: 2vh;
    }
   
}
.tehh{
    line-height: 0.9;
    font-family: "Anta";
    font-size: 5vh;
}

.service{
    font-family: "Anta";
    font-size: 5vh;
    color: orange;
    text-align: center;
    margin-top: 20px;
}
.app{
    font-family: "Anta";
    font-size: 4vh;
    text-align: center;
}
.trans{
    font-family: "Anta";
    font-size: 3vh;
    text-align: center;
}
.ser{
    height: 200px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
}
.seeeee{
    margin-top: 70px;
}
.serr{
    height: 200px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
}

.serrr{
    height: 200px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
}
.serererer{
    margin-top: 100px;
}

@media only screen  and (max-width: 768px){
    .service{
        font-family: "Anta";
        font-size: 2vh;
        color: orange;
        text-align: center;
        margin-top: 20px;
    }
    .app{
        font-family: "Anta";
        font-size: 2vh;
        text-align: center;
    }
    .trans{
        font-family: "Anta";
        font-size: 18px;
        text-align: center;
        color: rgb(109, 109, 109);
    }
    .seeeee{
        margin-top: 0px;
    }
    .serererer{
        margin-top: 10px;
    }
    
}
@media (min-width: 481px) and (max-width: 1280px){
    .service{
        font-family: "Anta";
        font-size: 3vh;
        color: orange;
        text-align: center;
        margin-top: 20px;
    }
    .app{
        font-family: "Anta";
        font-size: 2vh;
        text-align: center;
    }
    .trans{
        font-family: "Anta";
        font-size: 20px;
        text-align: center;
        color: rgb(109, 109, 109);
    }
    .seeeee{
        margin-top: 0px;
    }
    .ser{
        height: 200px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
    }
}
.whooooo{
    margin-left: 70px;
    color: orange;
    font-size: 5vh;
    font-family: "Anta";
}
.smart{
    font-size: 4vh;
    color: rgba(255, 166, 0, 0.76);
    font-family: "Anta";
    margin-left: 70px;
    line-height: 0.9;
}
.built{
    font-family: "Anta";
    font-size: 3vh;
    margin-left: 70px;
}

@media only screen  and (max-width: 768px){
    .whooooo{
        margin-left: 50px;
        color: orange;
        font-size: 5vh;
        font-family: "Anta";
    }
    .smart{
        font-size: 2vh;
        color: rgba(255, 166, 0, 0.76);
        font-family: "Anta";
        margin-left: 50px;
        line-height: 0.9;
    }
    .built{
        font-family: "Anta";
        font-size: 2vh;
        margin-left: 50px;
    }
    
}
@media (min-width: 481px) and (max-width: 1280px){
    .whooooo{
        margin-left: 50px;
        color: orange;
        font-size: 4vh;
        font-family: "Anta";
    }
    .smart{
        font-size: 3vh;
        color: rgba(255, 166, 0, 0.76);
        font-family: "Anta";
        margin-left: 50px;
        line-height: 0.9;
    }
    .built{
        font-family: "Anta";
        font-size: 2vh;
        margin-left: 50px;
    }
}

.whoom{
        color: rgb(0, 0, 0);
        font-size: 4vh;
        font-family: "Anta";
}
.wh{
    color: rgb(29, 29, 29);
    font-size: 4vh;
    font-family: "Anta";
}
.reach{
    display: flex;
    justify-content: end;
}
@media only screen  and (max-width: 768px){
    .whoom{
        color: rgb(0, 0, 0);
        font-size: 2vh;
        font-family: "Anta";
}
.wh{
    color: rgb(29, 29, 29);
    font-size: 2vh;
    font-family: "Anta";
    
}
.reach{
    display: flex;
    justify-content: end;
}
    
}
@media (min-width: 481px) and (max-width: 1280px){
    .whoom{
        color: rgb(0, 0, 0);
        font-size: 2vh;
        font-family: "Anta";
}
.wh{
    color: rgb(29, 29, 29);
    font-size: 2vh;
    font-family: "Anta";
}
.reach{
    display: flex;
    justify-content: end;
}
}
.butonngbdfddgfrdf{
    margin-left: 150px;
}